.codertype-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  color: #fff;
  min-height: 100vh;
}

.codertype-container h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #4a9e4a;
  text-shadow: 0 0 10px rgba(74, 158, 74, 0.3);
}

.start-screen {
  text-align: center;
  margin-top: 3rem;
}

.start-screen h2 {
  color: #4a9e4a;
  margin-bottom: 1rem;
}

.start-screen p {
  color: #ccc;
  margin-bottom: 2rem;
}

button {
  background-color: #4a9e4a;
  color: #fff;
  border: none;
  padding: 0.6rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  /* background-color: #3d833d; */
  transform: scale(1.05);
}

.game-screen {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.timer {
  text-align: center;
  font-size: 1.2rem;
  color: #4a9e4a;
}

.code-display {
  background-color: #1e1e1e;
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid #333;
}

.code-display pre {
  margin: 0;
  white-space: pre-wrap;
  color: #fff;
  font-family: 'Space Mono', monospace;
}

.textarea-codertype {
  /* width: 100%; */
  height: 200px;
  background-color: #2d2d2d;
  border: 1px solid #444;
  border-radius: 8px;
  padding: 1rem;
  color: #fff;
  font-family: 'Space Mono', monospace;
  resize: none;
}

.language-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media (min-width: 768px) {
  .language-buttons {
    flex-direction: row;
    justify-content: center;
  }
}

.language-buttons button {
  background-color: #1e1e1e;
  color: #888;
  border: 1px solid #333;
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 120px;
}

.language-buttons button:hover {
  background-color: #2d2d2d;
  color: #fff;
  border-color: #444;
  transform: scale(1.05);
}

.language-buttons button.active {
  background-color: #2d2d2d;
  color: #fff;
  border-color: #444;
  transform: scale(1.05);
}

.language-buttons button:focus {
  outline: none;
  border-color: #4a9e4a;
}

.language-buttons button:active {
  transform: scale(0.98);
}


/* textarea:focus {
  outline: none;
  border-color: #4a9e4a;
  box-shadow: 0 0 5px rgba(74, 158, 74, 0.3);
} */

.results-screen {
  text-align: center;
  margin-top: 2rem;
}

.results-screen h2 {
  color: #4a9e4a;
  margin-bottom: 2rem;
}

.stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.stat-item {
  background-color: #1e1e1e;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid #333;
}

.stat-item span {
  color: #4a9e4a;
  display: block;
  margin-bottom: 0.5rem;
}
