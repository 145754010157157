/* General Styles */
.home-container-base {
  background-color: rgb(18, 18, 18);
}
html, body {
  margin: 0;
  height: 100%; /* Make the body take full height */
}

.home-container {
  color: #333;
  line-height: 1.6;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;    /* Center vertically */
  flex-direction: column; /* Stack content vertically */
  height: auto;
  padding: 0;             /* Remove padding to avoid shifting */
  text-align: center;     /* Keep text centered */
  max-width: 700px;
  margin: 0 auto;
  flex: 1;
}

.home-header {
  margin-top: 20vh;
  color: white;
  padding: 2em 0;
  text-align: left;
}
.home-header h2 {
  font-size: 2em;
  margin: 0;
  font-weight: 600;
  background: linear-gradient(45deg, #ffffff, #e0e0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  transform-style: preserve-3d;
  animation: fadeInTitle 1s ease-out 0.3s forwards;
  opacity: 0;
  margin-bottom: 30px;
}

@keyframes fadeInTitle {
  from { opacity: 0; transform: translateY(-20px) translateZ(-50px); }
  to { opacity: 1; transform: translateY(0) translateZ(0); }
}

.home-subtitle {
  margin-top: 0.5em;
  position: relative;
  z-index: 2;
}

.home-about,
.home-projects {
  margin: 2em 0;
}

.home-about h2,
.home-projects h2 {
  font-size: 1.8em;
  color: #1db954;
}

.home-button {
  background-color: #1db954;
  color: white;
  border: none;
  padding: 0.8em 1.5em;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 1em;
  transition: background-color 0.3s ease;
}

.home-button:hover {
  background-color: #148a3b;
}

.home-footer {
  margin-top: 2em;
  font-size: 0.9em;
  color: #777;
}

/* Home Links Container */
.home-links {
  display: flex;
  flex-wrap: wrap;  /* Allow the items to wrap when necessary */
  gap: 15px;
  justify-content: center;
  margin-top: 10vh;
  overflow: hidden;  /* Prevent horizontal scrollbar */
  position: relative;  /* To position the links in relation to the parent container */
  z-index: 2;  /* Ensure the links are above other elements */
}

/* Link Button Style */
.link-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid; /* Outline color matches text color */
  background-color: transparent; /* Transparent background */
  color: white; /* Default white text */
  text-decoration: none;
  padding: 10px 10px;
  border-radius: 10px;
  transition: all 0.3s ease;
  font-size: small;
  /* Prevent shrinking and ensure consistent width */
  flex-shrink: 0;
}

/* Media Query for smaller screens */
@media (max-width: 680px) {
  .home-links {
    flex-direction: column; /* Stack the links vertically */
    align-items: center; /* Center the links */
  }

  .link-button {
    width: 50%; /* Make each link take full width in the column */
    margin-bottom: 10px; /* Add space between links */
  }

  .home-image-section {
    width: 80% !important;
    right: 0 !important;
    overflow: hidden; /* Prevent the section from causing overflow */
    position: relative; /* Ensure positioning doesn't affect the layout */
  }

  .home-large-image {
    min-height: 0;
    max-width: 100%; /* Ensure the image doesn't exceed its parent */
    height: auto;
    object-fit: cover; /* Ensure the image scales properly */
    position: absolute; /* Allows precise placement within the parent */
    right: -10%; /* Adjust the image to the right without causing overflow */
  }

  .home-container {
    height: auto;
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }

  .home-subtitle {
    font-size: 0.8em;
    margin-inline: 5%;
  }

  .home-header h2 {
    margin-left: 5%;
  }
}


.link-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.link-icon {
  width: 24px;
  height: 24px;
}

/* Button Variants */
.instagram {
  border-color: #8c62a9; /* Instagram purple */
  color: #8c62a9;
}

.youtube {
  border-color: #ff6a6a; /* YouTube red */
  color: #ff6a6a;
}

.linkedin {
  border-color: #437fbb; /* LinkedIn blue */
  color: #437fbb;
}

.github {
  border-color: #333; /* GitHub dark gray */
  color: #333;
}

.contact {
  border-color: #69ca8c; /* WhatsApp green */
  color: #69ca8c;
}

/* Hover Effects */
.link-button:hover {
  background-color: white; /* White background on hover */
  color: black; /* Black text on hover */
}

.link-button.instagram:hover,
.link-button.youtube:hover,
.link-button.linkedin:hover,
.link-button.github:hover,
.link-button.contact:hover {
  opacity: 0.8; /* Slight fade effect */
}

.home-images {
  display: flex;
  justify-content: space-around;
  margin: 2em 0;
}

.home-image {
  width: 150px;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease;  /* Smooth scaling effect */
}

.home-image-link {
  text-decoration: none;  /* Remove underline */
}

.home-image-link:hover .home-image {
  transform: scale(1.05);  /* Slight zoom effect on hover */
}

/* Home Image Section */
.home-image-section {
  position: absolute;
  top: 5%;
  right: 30%;
  width: 20%;
  z-index: 1;
}

.home-large-image {
  width: 100%;
  /* height: auto; */
  object-fit: cover; 
  position: relative;
  min-height: 33vh;
  filter: brightness(50%); 
  border-radius: 0%;
}
