/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #2c2c2c;
    padding: 2em;
    border-radius: 12px;
    max-width: 800px;
    width: 80%;
    position: relative;
    color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  /* Modal Header */
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
  }
  
  .close-btn {
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5em;
    cursor: pointer;
  }
  
  .modal-image {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 1em;
  }
  
  /* Modal Body */
  .modal-body {
    font-size: 1.1em;
  }
  
  .modal-stack ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }
  
  .modal-stack ul li {
    margin-right: 1em;
    margin-bottom: 0.5em;
  }
  
  .modal-link {
    display: inline-block;
    margin-top: 1em;
    color: #1db954;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .modal-link:hover {
    color: #17a34a;
  }
  
  @media (max-width: 800px) {
    .modal-content {
        width: 70%;
        padding: 1em;
    }
  }